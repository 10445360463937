import { Card, CardContent, Chip, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info'
import Layout from '../components/Layout'
import SEO from '../components/seo'
//import ReactQuill from 'react-quill'
import React, { useState } from "react";
import 'react-quill/dist/quill.snow.css';
import { StringParam, useQueryParam } from "use-query-params"
import { Helmet } from 'react-helmet';


const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

const pageTitle = "Editor on WorkWithVisa.com"

const useStyles = makeStyles((theme) => ({
    postJobButton: {
      background: "#ff424d",
      textTransform: "none",
      margin: "4px",
      color: "white"
    },
    noLink: {
      textDecoration: "none",
      color: "inherit",
    },
    buttonParent: {
        /*
        display: "flex",
        flexFlow: "row-reverse"
        */
    }
  
  }));

const EditorPage = () => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [token, setToken] = useQueryParam("token", StringParam)

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    return(
        <Layout>
            <Helmet>
                <script src="https://js.stripe.com/v3"></script>
            </Helmet>
            <SEO title={pageTitle} description="The mission of WorkWithVisa.com is to find companies that provide Visa sponsorship for talented people."/>
            <Card>
                <CardContent>
                    <Chip
                        icon={<InfoIcon />}
                        label={pageTitle}
                        color="primary"
                        variant="outlined"
                    />
                    <div>
                        <br />
                        This is the place where you can become a <strong>WorkWithVisa.com verified partner</strong>!<br /><br />
                        We provide you 2 options:<br />
                        <ul>
                            <li>Have <strong>your own introduction sub-page</strong> on WorkWithVisa.com</li>
                            <li>Or just the link to your own webpage or contact details</li>
                        </ul>
                    </div>
                </CardContent>
                <CardContent>
                    <strong>Steps:</strong><br /><br />
                    1. <i>(optional)</i> If you would like to have your own introduction page, please start typing and <b>don't forget to press SAVE!</b>
                </CardContent>
                <CardContent>
                    <ReactQuill 
                        id="editor"
                        theme="snow" 
                        value={value} 
                        onChange={setValue} 
                        placeholder="Start typing..." 
                        style={{height: "30vh"}}
                        modules={{
                            toolbar: [
                              [{ 'header': [false] }],
                              ['bold', 'italic', 'underline','strike',],
                              [{'list': 'ordered'}, {'list': 'bullet'}, /*{'indent': '-1'}, {'indent': '+1'}*/],
                              ['link', ],
                              ['clean']
                            ],
                          }}
                    />
                    <br /><br /><br /><br />
                    <div className={classes.buttonParent}>
                        <Button color="white" className={classes.postJobButton} onClick={
                        event => {
                            event.stopPropagation()
                            let requestBody = {
                                agency: token,
                                intro: value
                            };
                            fetch(`/.netlify/functions/send_intro`, {
                                method: 'post',
                                body: JSON.stringify(requestBody)
                            })
                            .then(res => {
                                console.log(res.status, res.statusText)
                                if (res.status < 300 && res.status >= 200) {
                                    alert("Introduction was saved successfully!")
                                } else {
                                    alert(
                                        "There was an issue with saving your introduction: " + res.statusText + "\n\n" +
                                        "Please COPY and send it in e-mail!"
                                    );
                                }
                            })
                            .catch(reason => {
                                console.error(reason)
                                alert(
                                    "There was an issue with saving your introduction: " + reason + "\n\n" +
                                    "Please COPY and send it in e-mail!"
                                );
                            })
                        }
                        }>
                            SAVE
                        </Button>
                        or <i>(in case of an error)</i>
                        <Button color="white" className={classes.postJobButton} onClick={
                        event => {
                            event.stopPropagation();
                            copyToClipboard(value);
                            window.location.href = "mailto:relocation@workwithvisa.com?subject=" + 
                                encodeURIComponent("Verified partner introduction") + 
                                "&body=" + 
                                encodeURIComponent(
                                    "Hi WorkWithVisa.com team,\n\n" +
                                    "there was an issue with saving my introduction on the page.\n" + 
                                    "I am forwarding it to you by e-mail.\n\n" +
                                    "Token:\n" + 
                                    "----------\n" +
                                    token + "\n" +
                                    "----------\n\n" +
                                    "Introduction:\n" +
                                    "----------\n" +
                                    value + "\n" +
                                    "----------\n\n" +
                                    "Best regards,\n"
                                );
                        }
                        }>
                            COPY
                        </Button>
                        and send in e-mail to <a href="mailto:relocation@workwithvisa.com">relocation@workwithvisa.com</a>.
                    </div>
                </CardContent>
                <CardContent>
                    2. Choose your payment option: <br />
                    <ul>
                        <li>
                            <Button color="white" className={classes.postJobButton} onClick={
                                event => {
                                    event.stopPropagation()
                                    var stripe = Stripe('pk_live_51IZdTzLcjCBIaiDWrV2lnZuiUUG32UqJdePjYMtcuGDLBYZVue0XJU7iy9iYMBWvMjaDuN4MdTEHSA2eYCRixPCk00oTGnipld');

                                    /*
                                    * When the customer clicks on the button, redirect
                                    * them to Checkout.
                                    */
                                    stripe.redirectToCheckout({
                                        lineItems: [{price: 'price_1IZk5hLcjCBIaiDWHHq39Yhg', quantity: 1}],
                                        mode: 'subscription',
                                        /*
                                        * Do not rely on the redirect to the successUrl for fulfilling
                                        * purchases, customers may not always reach the success_url after
                                        * a successful payment.
                                        * Instead use one of the strategies described in
                                        * https://stripe.com/docs/payments/checkout/fulfill-orders
                                        */
                                        successUrl: 'https://workwithvisa.com/verified_partner/successful_payment',
                                        cancelUrl: 'https://workwithvisa.com/verified_partner/cancelled_payment',
                                        clientReferenceId: token
                                    })
                                    .then(function (result) {
                                        if (result.error) {
                                            /*
                                            * If `redirectToCheckout` fails due to a browser or network
                                            * error, display the localized error message to your customer.
                                            */
                                            alert("An error has happened:\n\n"+token+"\n\nPlease try again!\nIf the issue still exists, please contact us at info@workwithvisa.com!");
                                        }
                                    });
                                }
                                }>
                                    Pay on a monthly basis
                            </Button>
                            <i>(Monthly subscription, <strong>12 USD / month</strong>)</i>
                        </li>
                        <li>
                            <Button color="white" className={classes.postJobButton} onClick={
                                event => {
                                    event.stopPropagation()
                                    var stripe = Stripe('pk_live_51IZdTzLcjCBIaiDWrV2lnZuiUUG32UqJdePjYMtcuGDLBYZVue0XJU7iy9iYMBWvMjaDuN4MdTEHSA2eYCRixPCk00oTGnipld');
                                    /*
                                    * When the customer clicks on the button, redirect
                                    * them to Checkout.
                                    */
                                    stripe.redirectToCheckout({
                                        lineItems: [{price: 'price_1IZk5hLcjCBIaiDWcQY88xya', quantity: 1}],
                                        mode: 'subscription',
                                        /*
                                        * Do not rely on the redirect to the successUrl for fulfilling
                                        * purchases, customers may not always reach the success_url after
                                        * a successful payment.
                                        * Instead use one of the strategies described in
                                        * https://stripe.com/docs/payments/checkout/fulfill-orders
                                        */
                                        successUrl: 'https://workwithvisa.com/verified_partner/successful_payment',
                                        cancelUrl: 'https://workwithvisa.com/verified_partner/cancelled_payment',
                                        clientReferenceId: token
                                    })
                                    .then(function (result) {
                                        if (result.error) {
                                        /*
                                        * If `redirectToCheckout` fails due to a browser or network
                                        * error, display the localized error message to your customer.
                                        */
                                        alert("An error has happened:\n\n"+token+"\n\nPlease try again!\nIf the issue still exists, please contact us at info@workwithvisa.com!");
                                        }
                                    });
                                }
                                }>
                                    Pay yearly and get 2 months for FREE
                            </Button>
                            <i>(Annual subscription: <strong>120 USD / year</strong>)</i>
                        </li>
                    </ul>
                </CardContent>
            </Card>
        </Layout>
    )
}

export default EditorPage
